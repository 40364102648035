import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import productReducer from './slices/product';
import statisticReducer from './slices/statistic';
import transactionReducer from './slices/transaction';
import userReducer from './slices/user';
import contratReducer from './slices/contrat';
import updateReducer from './slices/update';
import notificationReducer from './slices/notification';
// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

export const statisticPersistConfig = {
  key: 'statistic',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const transactionPersistConfig = {
  key: 'transaction',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const userPersistConfig = {
  key: 'user',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const contratPersistConfig = {
  key: 'contrat',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const updatePersistConfig = {
  key: 'update',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const notificationPersistConfig = {
  key: 'notification',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  product: persistReducer(productPersistConfig, productReducer),
  statistic: persistReducer(statisticPersistConfig, statisticReducer),
  transaction: persistReducer(transactionPersistConfig, transactionReducer),
  user: persistReducer(userPersistConfig, userReducer),
  contrat: persistReducer(contratPersistConfig, contratReducer),
  update: persistReducer(updatePersistConfig, updateReducer),
  notification: persistReducer(notificationPersistConfig, notificationReducer),
});

export default rootReducer;
