import { useEffect, useState } from 'react';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import Label from '../../../components/label';
import SvgColor from '../../../components/svg-color';
import { useDispatch, useSelector } from '../../../redux/store';
import { getProducts } from '../../../redux/slices/product';
import { getTransactions } from '../../../redux/slices/transaction';
// import { getUser } from '../../../redux/slices/user';

// ----------------------------------------------------------------------

export default function NavConfig() {
  const { user } = useAuthContext();
  const icon = (name) => (
    <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  );

  const ICONS = {
    cart: icon('ic_cart'),
    user: icon('ic_user'),
    file: icon('ic_file'),
    analytics: icon('ic_analytics'),
    banking: icon('ic_banking'),
    dashboard: icon('ic_dashboard'),
    future: icon('ic_label'),
    booking: icon('ic_booking'),
    folder: icon('ic_folder'),
  };

  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.product);
  const { transactions } = useSelector((state) => state.transaction);
  // const { user } = useSelector((state) => state.user);
  const [productShopCountLength, setProductShopCountLength] = useState(0);
  const [waittingTransactionsCount, setWaittingTransactionsCount] = useState(0);

  useEffect(() => {
    dispatch(getProducts());
    dispatch(getTransactions());
  }, [dispatch]);

  useEffect(() => {
    const filteredProducts = products.filter(
      (product) =>
        product.inventoryType !== 'brouillon' &&
        product.inventoryType !== 'cloturé' &&
        !product.investorsList.includes(user.uid)
    );
    const filteredTransactions = transactions.filter(
      (transaction) =>
        transaction.waitting === true && (user.role !== 'user' || transaction.investor === user.uid)
    );
    setProductShopCountLength(filteredProducts.length);
    setWaittingTransactionsCount(filteredTransactions.length);
  }, [products, transactions, user]);

  const _navConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      items: [
        { title: 'Tableau de bord', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
        {
          title: 'Chargements',
          path: PATH_DASHBOARD.eCommerce.shop,
          icon: ICONS.cart,
          roles: ['user'],
          info: productShopCountLength > 0 && <Label color="info">{productShopCountLength}</Label>,
        },
        {
          title: 'Chargements',
          path: PATH_DASHBOARD.eCommerce.list,
          icon: ICONS.cart,
          roles: ['admin', 'manager'],
        },

        {
          title: 'Contrats',
          path: PATH_DASHBOARD.invoice.list,
          icon: ICONS.file,
        },
        /* user.lcbInvestor && user.role === 'user'
          ? {
              title: 'Espace LCB',
              path: PATH_DASHBOARD.general.lcbInvestor,
              icon: ICONS.booking,
              roles: ['user'],
            }
          : undefined,
         {
           title: 'Espace LCB',
           path: PATH_DASHBOARD.general.lcb,
           icon: ICONS.folder,
           roles: ['admin'],
         },
        {
          title: 'Statistiques',
          path: PATH_DASHBOARD.general.statistics,
          icon: ICONS.analytics,
          roles: ['admin'],
        },
        {
          title: 'Transactions',
          path: PATH_DASHBOARD.general.transactions,
          icon: ICONS.banking,
          info: waittingTransactionsCount > 0 && (
            <Label color="info">+{waittingTransactionsCount}</Label>
          ),
        }, */
        {
          title: 'Mon compte',
          path: PATH_DASHBOARD.user.account,
          icon: ICONS.user,
          roles: ['user'],
        },
        {
          title: 'Investisseurs',
          path: PATH_DASHBOARD.user.list,
          icon: ICONS.user,
          roles: ['manager'],
        },
        {
          title: 'Investisseurs',
          path: PATH_DASHBOARD.user.list,
          icon: ICONS.user,
          roles: ['admin'],
          children: [
            {
              title: 'Inscrits',
              path: PATH_DASHBOARD.user.list,
              roles: ['admin', 'manager'],
            },
            {
              title: 'Potentiels',
              path: PATH_DASHBOARD.user.future,
            },
          ],
        },
      ].filter(Boolean),
    },
  ];
  return _navConfig;
}
