import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Card,
  Table,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  CardHeader,
  TableContainer,
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// utils
import { fCurrency } from '../../../../utils/formatNumber';
// components
import Iconify from '../../../../components/iconify';
import Scrollbar from '../../../../components/scrollbar';
import { TableHeadCustom } from '../../../../components/table';
import { getProduct } from '../../../../redux/slices/product';
import { useDispatch, useSelector } from '../../../../redux/store';

// ----------------------------------------------------------------------

AppNewInvoice.propTypes = {
  title: PropTypes.string,
  tableData: PropTypes.array,
  subheader: PropTypes.string,
  tableLabels: PropTypes.array,
  products: PropTypes.array,
};

export default function AppNewInvoice({
  title,
  subheader,
  tableData,
  tableLabels,
  products,
  ...other
}) {
  const navigate = useNavigate();

  const copyTableData = [...tableData];

  const lastContrats = copyTableData
    .sort((a, b) => new Date(b.createDate) - new Date(a.createDate))
    .slice(0, 5);

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} sx={{ mb: 3 }} />
      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar>
          <Table sx={{ minWidth: 720 }}>
            <TableHeadCustom headLabel={tableLabels} />

            <TableBody>
              {lastContrats.map((row) => (
                <AppNewInvoiceRow key={row.id} row={row} products={products} />
              ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <Divider />

      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          size="small"
          color="inherit"
          endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
          onClick={() => navigate(PATH_DASHBOARD.invoice.list)}
        >
          Tout voir
        </Button>
      </Box>
    </Card>
  );
}

// ----------------------------------------------------------------------

AppNewInvoiceRow.propTypes = {
  row: PropTypes.object,
  products: PropTypes.array,
};

function AppNewInvoiceRow({ row, products }) {
  const dispatch = useDispatch();

  const { product } = useSelector((state) => state.product);

  useEffect(() => {
    dispatch(getProduct(row.product));
  }, [dispatch, row]);

  return (
    <TableRow>
      {row && products && (
        <>
          <TableCell>{row.invoiceNumber.toUpperCase()}</TableCell>

          <TableCell>{product?.code.toUpperCase()}</TableCell>

          <TableCell>{fCurrency(row.totalPrice)}</TableCell>

          <TableCell>{row.delay} Mois</TableCell>
        </>
      )}
    </TableRow>
  );
}
