import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import RoleBasedGuard from '../auth/RoleBasedGuard';
// layouts
import MainLayout from '../layouts/main';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  // Dashboard: General
  GeneralAppPage,
  GeneralBankingPage,
  GeneralLcbPage,
  GeneralPartenairePage,
  GeneralTransactionsPage,
  TransactionEditPage,
  // Dashboard: User
  UserListPage,
  FutureUserListPage,
  UserEditPage,
  UserCreatePage,
  UserAccountPage,
  // Dashboard: Ecommerce
  EcommerceShopPage,
  EcommerceCheckoutPage,
  EcommerceProductListPage,
  EcommerceProductEditPage,
  EcommerceProductCreatePage,
  EcommerceProductDetailsPage,
  // Dashboard: Invoice
  InvoiceListPage,
  InvoiceDetailsPage,
  InvoiceCreatePage,
  //
  Page500,
  Page403,
  Page404,
  ComingSoonPage,
  MaintenancePage,
  InvoiceEditPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralAppPage /> },
        {
          path: 'enterprise',
          element: (
            <RoleBasedGuard hasContent roles={['admin']}>
              <GeneralLcbPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'enterprise-investor',
          element: (
            <RoleBasedGuard hasContent roles={['user']}>
              <GeneralPartenairePage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'statistics',
          element: (
            <RoleBasedGuard hasContent roles={['admin']}>
              <GeneralBankingPage />
            </RoleBasedGuard>
          ),
        },
        { path: 'transactions', element: <GeneralTransactionsPage /> },
        { path: 'transactions/:id', element: <TransactionEditPage /> },
        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShopPage /> },
            { path: 'product/:id', element: <EcommerceProductDetailsPage /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <EcommerceProductListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'product/new',
              element: (
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <EcommerceProductCreatePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'product/:id/edit',
              element: (
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <EcommerceProductEditPage />
                </RoleBasedGuard>
              ),
            },
            { path: 'checkout', element: <EcommerceCheckoutPage /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            {
              path: 'list',
              element: (
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <UserListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'future',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <FutureUserListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <UserCreatePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id/edit',
              element: (
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <UserEditPage />
                </RoleBasedGuard>
              ),
            },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceListPage /> },
            { path: 'new', element: <InvoiceCreatePage /> },
            { path: ':id', element: <InvoiceDetailsPage /> },
            { path: ':id/edit', element: <InvoiceEditPage /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      element: <MainLayout />,
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
    { path: '/', element: <Navigate to="/dashboard" replace /> },
  ]);
}
