import PropTypes from 'prop-types';
// @mui
import { Box, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

TablePaginationCustom.propTypes = {
  onChangeDense: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  sx: PropTypes.object,
};

export default function TablePaginationCustom({
  onChangeDense,
  rowsPerPageOptions = [5, 10, 25],
  sx,
  ...other
}) {
  const { t } = useTranslation();
  return (
    <Box sx={{ position: 'relative', ...sx }}>
      <TablePagination labelRowsPerPage={t('Lignes par page')} rowsPerPageOptions={rowsPerPageOptions} component="div" {...other} />
    </Box>
  );
}
