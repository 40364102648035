import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAnalytics, setUserId, setUserProperties, logEvent } from 'firebase/analytics';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from 'firebase/auth';
import { getFirestore, collection, doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore';
// config
import { FIREBASE_API } from '../config-global';

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);
const analytics = getAnalytics(firebaseApp);

const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp);

export default function useAuthTracking() {
  const location = useLocation;

  useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(AUTH, (user) => {
      if (user) {
        setUserId(analytics, user.uid);
        setUserProperties(analytics, {
          email: user.email,
          displayName: user.displayName,
          provider: user.providerData[0]?.providerId,
        });
        logEvent(analytics, 'login', {
          method: user.providerData[0]?.providerId,
        });
      } else {
        setUserId(analytics, '');
        setUserProperties(analytics, {});
        logEvent(analytics, 'logout');
      }
    });

    return () => unregisterAuthObserver();
  }, [location]);
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          const userRef = doc(DB, 'users', user.uid);

          const docSnap = await getDoc(userRef);

          const profile = docSnap.data();

          dispatch({
            type: 'INITIAL',
            payload: {
              isAuthenticated: true,
              user: {
                ...user,
                ...profile,
                firstname: profile.firstName,
                lastname: profile.lastName,
                displayName:
                  profile.displayName === ''
                    ? `${profile.firstName} ${profile.lastName}`
                    : profile.displayName,
                photoURL: profile.profilePicture,
                role: profile.role,
                email: profile.email,
                address: profile.address,
                city: profile.city,
                country: profile.country,
                isVerified: profile.isVerified,
                phoneNumber: profile.phoneNumber,
                totalInvested: profile.totalInvested,
                currentInvestment: profile.currentInvestment,
                investmentDone: profile.investmentDone,
                status: profile.status,
              },
            },
          });
        } else {
          dispatch({
            type: 'INITIAL',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // Get a document from firestore
  const getDocFromFirestore = useCallback(async (docPath) => {
    try {
      const docRef = doc(DB, docPath);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
      }
    } catch (error) {
      console.error('Error getting document:', error);
    }
    return null;
  }, []);

  // LOGIN
  const login = useCallback(
    async (email, password) => {
      await signInWithEmailAndPassword(AUTH, email, password);
      await getDocFromFirestore(`users/${AUTH.currentUser.uid}`).then((userObj) => {
        const userStatus = userObj.status;
        if (userStatus === 'banned') {
          signOut(AUTH);
          alert(
            'Votre compte a été bloqué. Veuillez contacter un administrateur pour plus de détails.'
          );
        }
      });
    },
    [getDocFromFirestore]
  );

  // REGISTER
  const register = useCallback(
    async (
      email,
      password,
      firstName,
      lastName,
      phoneNumber,
      address,
      country,
      avatarUrl,
      isVerified,
      status,
      role,
      currentInvestment,
      totalInvested,
      totalRefund,
      investmentDone,
      createdAt,
      lcbInvestor,
    ) => {
      const secondaryApp = initializeApp(FIREBASE_API, 'secondary');
      const secondaryAUTH = getAuth(secondaryApp);
      await createUserWithEmailAndPassword(secondaryAUTH, email, password)
        .then(async (res) => {
          const userRef = doc(collection(DB, 'users'), res.user?.uid);
          await setDoc(userRef, {
            uid: res.user?.uid,
            email,
            displayName: `${firstName} ${lastName}`,
            firstName,
            lastName,
            phoneNumber,
            address,
            country,
            profilePicture: avatarUrl,
            isVerified,
            status,
            role,
            currentInvestment,
            totalInvested,
            totalRefund,
            investmentDone,
            createdAt,
            lcbInvestor,
          });
        })
        .then(() => secondaryAUTH.signOut())
        .then(() => secondaryApp.delete())
        .catch((error) => {
          console.error(error);
        });
    },
    []
  );

  // UPDATE USER PASSWORD
  const updateUserPassword = useCallback(async (oldPassword, newPassword) => {
    const user = AUTH.currentUser;
    if (user) {
      const credentials = EmailAuthProvider.credential(user.email, oldPassword);
      await reauthenticateWithCredential(user, credentials);
      await updatePassword(user, newPassword);
    }
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    signOut(AUTH);
  }, []);

  // Add a document to a collection in firestore
  const addDocToCollection = useCallback(async (collectionPath, documentData, documentId) => {
    try {
      const collectionRef = collection(DB, collectionPath);
      const docRef = doc(collectionRef, documentId);
      await setDoc(docRef, documentData);
    } catch (error) {
      console.error('Error adding document to collection:', error);
    }
  }, []);

  // Get all documents from a collection in firestore
  const getAllDocsFromCollection = useCallback(async (collectionPath) => {
    try {
      const collectionRef = collection(DB, collectionPath);
      const querySnapshot = await getDoc(collectionRef);
      const documents = querySnapshot.docs.map((_doc) => ({ id: _doc.id, ..._doc.data() }));
      return documents;
    } catch (error) {
      console.error('Error getting documents:', error);
    }
    return null;
  }, []);

  // Update a document in firestore
  const updateDocInFirestore = useCallback(async (docPath, updatedData) => {
    try {
      const docRef = doc(DB, docPath);
      await setDoc(docRef, updatedData, { merge: true });
    } catch (error) {
      console.error('Error updating document:', error);
    }
  }, []);

  // update notifications
  const updateInformations = useCallback(
    async (userId, newInformation, type) => {
      try {
        const docRef = doc(DB, `informations/${type}`);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
          await setDoc(docRef, {});
        }
        const informations = await getDocFromFirestore(`informations/${type}`);
        const userInformations = informations[userId] || [];
        userInformations.push(newInformation);
        await updateDocInFirestore(`informations/${type}`, { [userId]: userInformations });
      } catch (error) {
        console.error("Erreur lors de l'ajout de l'information :", error);
      }
    },
    [getDocFromFirestore, updateDocInFirestore]
  );

  // Delete a document from firestore
  const deleteDocFromFirestore = useCallback(async (docPath) => {
    try {
      const docRef = doc(DB, docPath);
      await deleteDoc(docRef);
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  }, []);

  // ADD FILE TO FIREBASE STORAGE

  const uploadFile = useCallback(async (path, file) => {
    try {
      const storage = getStorage(firebaseApp);
      const storageRef = ref(storage, `${path}.${file.name.split('.').pop()}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            console.log(snapshot.bytesTransferred);
          },
          (error) => {
            console.log(error);
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'firebase',
      login,
      register,
      logout,
      addDocToCollection,
      getDocFromFirestore,
      getAllDocsFromCollection,
      updateDocInFirestore,
      deleteDocFromFirestore,
      uploadFile,
      updateUserPassword,
      updateInformations,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      register,
      logout,
      addDocToCollection,
      getDocFromFirestore,
      getAllDocsFromCollection,
      updateDocInFirestore,
      deleteDocFromFirestore,
      uploadFile,
      updateUserPassword,
      updateInformations,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
