import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  transactions: [],
  transaction: null,
};

const slice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TRANSACTIONS
    getTransactionsSuccess(state, action) {
      state.isLoading = false;
      state.transactions = action.payload;
    },

    // GET TRANSACTION
    getTransactionSuccess(state, action) {
      state.isLoading = false;
      state.transaction = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export function getTransactions() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/transactions');
      dispatch(slice.actions.getTransactionsSuccess(response.data.transactions));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTransaction(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/transactions/${id}`);
      dispatch(slice.actions.getTransactionSuccess(response.data.transaction));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
