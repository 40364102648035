import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  users: [],
  futureUsers: [],
  user: null,
  _user: null,
};

const isObjectEqual = (obj1, obj2) => obj1.id === obj2.id;

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // GET USER
    getUserSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
      state._user = action.payload;
    },
    // GET FUTURE USERS
    getFutureUsersSuccess(state, action) {
      state.isLoading = false;
      state.futureUsers = action.payload;
    },
    
    // HOT UPDATE
    hotUpdateFutureUsers(state, action) {
      state.futureUsers = [...state.futureUsers, action.payload];
    },

    // HOT DELETE
    
    
    hotDeleteFutureUser(state, action) {
      state.futureUsers = state.futureUsers.filter(user => !isObjectEqual(user, action.payload));
    }
    
    
    
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  hotUpdateFutureUsers,
  hotDeleteFutureUser
} = slice.actions;

// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/users');
      dispatch(slice.actions.getUsersSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUser(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/users/${id}`);
      dispatch(slice.actions.getUserSuccess(response.data.user));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFutureUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/users');
      dispatch(slice.actions.getFutureUsersSuccess(response.data.futureUsers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

