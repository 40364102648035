// @mui
import { Typography } from '@mui/material';
// layouts
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';

// ----------------------------------------------------------------------

export default function Login() {

  return (
    <LoginLayout>
      <Typography variant="h4" mb={3} >Connexion</Typography>
      <AuthLoginForm />
    </LoginLayout>
  );
}
