import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  contrats: [],
  contrat: null,
};

const slice = createSlice({
  name: 'contrat',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CONTRATS
    getContratsSuccess(state, action) {
      state.isLoading = false;
      state.contrats = action.payload;
    },

    // GET CONTRAT
    getContratSuccess(state, action) {
      state.isLoading = false;
      state.contrat = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export function getContrats() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/contrats');
      dispatch(slice.actions.getContratsSuccess(response.data.contrats));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getContrat(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/contrats/${id}`);
      dispatch(slice.actions.getContratSuccess(response.data.contrat));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}