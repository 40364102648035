import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  statistics: [],
  statitistic: null,
};

const slice = createSlice({
  name: 'statistic',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    
    // GET STATISTICS
    getStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.statistics = action.payload;
    },

    // GET STATISTIC
    getStatisticSuccess(state, action) {
      state.isLoading = false;
      state.statistic = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export function getStatistics() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/statistics/all');
      dispatch(slice.actions.getStatisticsSuccess(response.data.statistics));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getStatistic(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/statistics/${id}`);
      dispatch(slice.actions.getStatisticSuccess(response.data.statistics));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
