// import dotenv from 'dotenv';
// routes
import { PATH_DASHBOARD } from './routes/paths';

// dotenv.config();

// API
// ----------------------------------------------------------------------

export const HOST_API_KEY = 'https://api.lcb-invest-support.com/';

export const FIREBASE_API = {
  apiKey: 'AIzaSyAhwikXLY___9ufIsDB7txr4f1jjuepcSA',
  authDomain: 'lcb-invest-1287a.firebaseapp.com',
  projectId: 'lcb-invest-1287a',
  storageBucket: 'lcb-invest-1287a.appspot.com',
  messagingSenderId: '637505713549',
  appId: '1:637505713549:web:bea587bba344d82062f9c4',
  measurementId: 'G-XE1YRE05WM',
};

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};
