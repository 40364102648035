import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  products: [],
  product: null,
  checkout: {
    activeStep: 0,
    cart: [],
    investmentAmount: 0,
    investmentDelay: 1,
    uploadedContrat: null,
    investmentId: null,
  },
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;
      state.checkout.cart = cart;
      state.checkout.investmentAmount = state.checkout.investmentAmount || 0;
      state.checkout.investmentDelay = state.checkout.investmentDelay || 1;
      state.checkout.uploadedContrat = state.checkout.uploadedContrat || null;
      state.checkout.investmentId = state.checkout.investmentId || null;
    },

    addToCart(state, action) {
      const newProduct = action.payload;
      state.checkout.cart = [newProduct];
    },

    deleteCart(state, action) {
      const updateCart = state.checkout.cart.filter((product) => product.id !== action.payload);

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.cart = [];
      state.checkout.billing = null;
      state.checkout.activeStep = 0;
      state.checkout.investmentAmount = 0;
      state.checkout.investmentDelay = 0;
    },

    backStep(state) {
      state.checkout.activeStep -= 1;
    },

    nextStep(state) {
      state.checkout.activeStep += 1;
    },

    gotoStep(state, action) {
      const step = action.payload;
      state.checkout.activeStep = step;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    updateInvestmentAmount(state, action) {
      state.checkout.investmentAmount = action.payload;
    },

    updateInvestmentDelay(state, action) {
      state.checkout.investmentDelay = action.payload;
    },
    
    updateInvestmentId(state, action) {
      state.checkout.investmentId = action.payload;
    },

    uploadContrat(state, action) {
      state.checkout.uploadedContrat = action.payload;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addToCart,
  resetCart,
  gotoStep,
  backStep,
  nextStep,
  deleteCart,
  createBilling,
  applyShipping,
  updateInvestmentAmount,
  updateInvestmentDelay,
  updateInvestmentId,
  uploadContrat,
} = slice.actions;

// ----------------------------------------------------------------------

export function getProducts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/products');
      dispatch(slice.actions.getProductsSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProduct(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/products/${id}`);
      dispatch(slice.actions.getProductSuccess(response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
