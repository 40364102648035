import { useLocation, Navigate } from 'react-router-dom';
// auth
import { useAuthContext } from '../../auth/useAuthContext';

export default function MainLayout() {
  const { user } = useAuthContext();
  const { pathname } = useLocation();
  
  const redirectDashboard = user && pathname === '/' ? <Navigate to="/dashboard" replace /> : null;
  const redirectLogin = !user && pathname !== '/auth/login' ? <Navigate to="/auth/login" replace /> : null;
  if (redirectDashboard || redirectLogin) {
    return (
      <>
        {redirectDashboard}
        {redirectLogin}
      </>
    );
  }
}
